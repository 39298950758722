import { ModalIconType } from '../../shared/components/generic-modal/modal-icon.type';

export class GenericModalData {
    title: string;
    message: string;
    cancelButtonText: string;
    confirmButtonText: string;
    hasConfirmButton: boolean;
    hasCancelButton: boolean;
    hasTitle: boolean;
    iconType: ModalIconType;
    smallContainer: boolean;
    hasCloseButton: boolean;
}
