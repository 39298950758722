import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { OrderGuideSearchModel } from '../../models/order-guide/order-guide-search.model';
import { ViewModes } from '../../enums/view-mode.enum';
import { CustomerItemOrderGuide } from '../../models/customer-item-order-guide.model';
import { DiscountPriceRequest } from '../../models/order-guide/discount-price-request.model';
import { Observable } from 'rxjs';
import { GeneralCartModel } from '../../models/cart/general-cart.model';
import { SearchResponse } from '../../models/search-response.model';
import { ProductCatalogModel } from '../../models/product/product-catalog.model';

@Injectable({
    providedIn: 'root'
})

export class OrderGuideService {

    constructor(private http: HttpClient) { }

    getOrderGuide(params: OrderGuideSearchModel): Observable<SearchResponse<ProductCatalogModel>> {
        return this.http.post<SearchResponse<ProductCatalogModel>>(`${environment.api.content}/Products/search`, {
            page: params.page,
            sort: params.sort,
            viewModes: params.viewModes,
            vendors: params.vendors,
            categories: params.categories,
            isMasterCategory: params.isMasterCategory,
            keyword: params.keyword,
            customerSelectedSiteId: params.customerSelectedSiteId,
            customerSelectedAccountId: params.customerSelectedAccountId,
            pageSize: params.pageSize
        });
    }

    print(params: OrderGuideSearchModel) {
        return this.http.post(`${environment.api.content}/Products/get_order_guide_pdf`, {
            page: params.page,
            sort: params.sort,
            viewModes: params.viewModes,
            vendors: params.vendors,
            categories: params.categories,
            isMasterCategory: params.isMasterCategory,
            keyword: params.keyword
        },
            { responseType: 'blob' as 'json' }
        );
    }

    @Cacheable()
    getCategories(params: { sort: number, vendors: Array<number>, categories: Array<number>, includeAll: boolean, viewModes: Array<ViewModes>, customerSelectedSiteId?: number, customerSelectedAccountId?: number; }) {
        return this.http.post(`${environment.api.content}/Category`, {
            sort: params.sort,
            viewModes: params.viewModes,
            vendors: params.vendors,
            categories: params.categories,
            includeAll: params.includeAll,
            customerSelectedSiteId: params.customerSelectedSiteId,
            customerSelectedAccountId: params.customerSelectedAccountId
        });
    }

    loadCustomersList(vendorCustomerId: number) {
        return this.http.get(`${environment.api.content}/OrderGuide/${vendorCustomerId}/load_customers`)
    }

    saveCustomer(vendorCustomerId: number, request: CustomerItemOrderGuide) {
        return this.http.post(`${environment.api.content}/OrderGuide/${vendorCustomerId}/save_customers`, request)
    }

    saveSpecialPrice(vendorCustomerId: number, request: DiscountPriceRequest): Observable<number> {
        return this.http.post<number>(`${environment.api.content}/OrderGuide/discount/${vendorCustomerId}/save_special_price`, request);
    }

    resetSpecialPrice(request: DiscountPriceRequest): Observable<boolean> {
        return this.http.post<boolean>(`${environment.api.content}/OrderGuide/discount/reset_special_price`, request);
    }

    loadShoppingCart(request: { customerAccountId: number, customerSiteId: number }): Observable<GeneralCartModel[]> {
        return this.http.post<GeneralCartModel[]>(`${environment.api.content}/OrderGuide/load_shopping_cart`, request)
    }

}
