import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { LoadEditOrderProducts } from '../../actions/order.actions';
import { selectEditProducts, selectLoading } from '../../selectors/order.selector';
import { PlatformType } from 'src/environments/platform-type.enum';
import { environment } from 'src/environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { getThemeId } from '../../selectors/branding.selector';
import { TemplateEnum } from 'src/app/core/enums/template.enum';

@Component({
    selector: 'app-add-product',
    templateUrl: './add-product.component.html',
    styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

    @Input() orderId: number;

    @Input() vendorId: number;

    @Input() buyerSiteId: number;

    @Output() selected = new EventEmitter<{ productId: number, discountPrice?: number }>();

    public products$: Observable<any[]>;
    public loading$: Observable<boolean>;
    template$: Observable<TemplateEnum>;
    TemplateEnum = TemplateEnum;

    productsLength = 0;

    form: FormGroup;

    @ViewChild('addProductMenuTrigger', { static: true }) menuTrigger: MatMenuTrigger;
    PlatformType = PlatformType;
    currentPlatform = environment.platformId;

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.loading$ = this.store.select(selectLoading);

        this.products$ = this.store.select(selectEditProducts).pipe(
            tap((products) => {
                if (products?.length > 0) {
                    this.productsLength = products.length;
                    this.menuTrigger.openMenu();
                } else {
                    this.productsLength = 0;
                    this.menuTrigger.closeMenu();
                }
            })
        );

        this.form = this.fb.group({
            keyword: [null, []]
        });

        this.form.controls.keyword.valueChanges
            .pipe(
                debounceTime(500),
            ).subscribe((keyword) => {
                if (!keyword || keyword.length < 2) {
                    return;
                }

                this.store.dispatch(new LoadEditOrderProducts({
                    orderId: this.orderId,
                    vendorId: this.vendorId,
                    buyerSiteId: this.buyerSiteId,
                    keyword
                }));
            });
    }

    onSelectProduct(product) {
        const productId = product.id;
        const discountPrice = product.vendors[0].discount_price;
        this.selected.emit({ productId, discountPrice });
        this.form.controls.keyword.reset();
        this.form.updateValueAndValidity();
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
    }

    openMenu(): void {
        if (this.productsLength > 0) {
            this.menuTrigger.openMenu();
        } else {
            this.menuTrigger.closeMenu();
        }
    }
}
