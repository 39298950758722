import { Action } from '@ngrx/store';
import { LeadsType } from 'src/app/core/enums/leads-type.enum';
import { CustomerDetailsModel, CustomerDetailsUpdateModel } from 'src/app/core/models/customer/customer-details.model';
import { CustomerInviteModel, CustomerInvitesBackendModel } from 'src/app/core/models/customer/customer-invites.model';
import { CustomerInvitesFilter } from 'src/app/core/models/customer/customer-invites.filter';
import { ResendInviteModel } from 'src/app/core/models/customer/resend-invite.model';
import { LeadsInfoModel } from 'src/app/core/models/customer/leads-info.model';

export enum CustomerActionTypes {
    LoadCustomers = '[Customer] Load Customers',
    CustomersLoaded = '[Customer] Customers Loaded Success',
    LoadCustomerLeads = '[Customer] Load Customer Leads',
    CustomerLeadsLoaded = '[Customer] Customer Leads Loaded Success',
    CustomerDetails = '[Customer] Customer Details Load',
    CustomerDetailsSuccess = '[Customer] Customer Details Load Success',
    UpdateCustomerDetails = '[Customer] Customer Details Update',
    UpdateCustomerDetailsSuccess = '[Customer] Customer Details Update Success',
    LoadCustomerInvites = '[Customer] Load Customer Invites',
    LoadCustomerInvitesSuccess = '[Customer] Load Customer Invites Success',
    SendInvitation = '[Customer] Send Invitation',
    SendInvitationSuccess = '[Customer] Send Invitation Success',
    SendInvitationFailed = '[Customer] Send Invitation Failed',
    DeleteInvitation = '[Customer] Delete Invitation',
    DeleteInvitationSuccess = '[Customer] Delete Invitation Success',
    SaveLeadsStatus = '[Customer] Save Leads Status Action',
    SaveLeadsStatusSuccess = '[Customer] Sav Leads Status Success ',
    ClearCustomerDetails = '[Customer] Clear CustomerDetails',
    ClearCustomers = '[Customer] Clear Customers',
    ClearCustomerLeads = '[Customer] Clear CustomerLeads',
    ClearCustomerInvites = '[Customer] Clear CustomerInvites',
    LoadCompatiblePriceLevelsAction = '[Customer] Load CompatiblePriceLevels',
    PriceLevels = '[Customer] Load CompatiblePriceLevels',
    PriceLevelsSuccess = '[Customer] CompatiblePriceLevelsList Loaded Success'
}

export class LoadCustomers implements Action {
    readonly type = CustomerActionTypes.LoadCustomers;

    constructor(public payload: {
        page: number, statuses?: number[],
        priceLevels?: Array<number>,
        keyword?: string,
        orderedDate?: { start: Date, end: Date; },
        lastOrderedDate?: { start: Date, end: Date; };
    }) { }
}

export class CustomersLoaded implements Action {
    readonly type = CustomerActionTypes.CustomersLoaded;

    constructor(public payload: { customers: any; }) { }
}

export class LoadCustomerLeads implements Action {
    readonly type = CustomerActionTypes.LoadCustomerLeads;

    constructor(public payload: { page: number, isArchived: LeadsType, keyword?: string; }) { }
}

export class CustomerLeadsLoaded implements Action {
    readonly type = CustomerActionTypes.CustomerLeadsLoaded;

    constructor(public payload: { customerLeads: any; }) { }
}

export class CustomerDetails implements Action {
    readonly type = CustomerActionTypes.CustomerDetails;

    constructor(public payload) { }
}

export class CustomerDetailsSuccess implements Action {
    readonly type = CustomerActionTypes.CustomerDetailsSuccess;

    constructor(public payload: CustomerDetailsModel) { }
}

export class UpdateCustomerDetails implements Action {
    readonly type = CustomerActionTypes.UpdateCustomerDetails;

    constructor(public payload: { customerId: number, customerDetailsUpdate: CustomerDetailsUpdateModel; }) { }
}

export class UpdateCustomerDetailsSuccess implements Action {
    readonly type = CustomerActionTypes.UpdateCustomerDetailsSuccess;

    constructor(public payload: any) { }
}

export class LoadCustomerInvites implements Action {
    readonly type = CustomerActionTypes.LoadCustomerInvites;

    constructor(public payload: CustomerInvitesFilter) { }
}

export class LoadCustomerInvitesSuccess implements Action {
    readonly type = CustomerActionTypes.LoadCustomerInvitesSuccess;

    constructor(public payload: CustomerInvitesBackendModel) { }
}

export class SendInvitation implements Action {
    readonly type = CustomerActionTypes.SendInvitation;

    constructor(public payload: ResendInviteModel) { }
}

export class SendInvitationSuccess implements Action {
    readonly type = CustomerActionTypes.SendInvitationSuccess;

    constructor(public payload: CustomerInviteModel) { }
}


export class DeleteInvitation implements Action {
    readonly type = CustomerActionTypes.DeleteInvitation;

    constructor(public payload: number) { }
}

export class DeleteInvitationSuccess implements Action {
    readonly type = CustomerActionTypes.DeleteInvitationSuccess;

    constructor(public payload: CustomerInviteModel) { }
}

export class SaveLeadsStatus implements Action {
    readonly type = CustomerActionTypes.SaveLeadsStatus;

    constructor(public payload: { leadsInfo: LeadsInfoModel; }) { }
}

export class SaveLeadsStatusSuccess implements Action {
    readonly type = CustomerActionTypes.SaveLeadsStatusSuccess;

    constructor(public payload: { id: any; }) { }
}

export class SendInvitationFailed implements Action {
    readonly type = CustomerActionTypes.SendInvitationFailed;
}

export class ClearCustomerDetails implements Action {
    readonly type = CustomerActionTypes.ClearCustomerDetails;
}

export class ClearCustomers implements Action {
    readonly type = CustomerActionTypes.ClearCustomers;
}

export class ClearCustomerLeads implements Action {
    readonly type = CustomerActionTypes.ClearCustomerLeads;
}

export class ClearCustomerInvites implements Action {
    readonly type = CustomerActionTypes.ClearCustomerInvites;
}

export class LoadCompatiblePriceLevels implements Action {
    readonly type = CustomerActionTypes.LoadCompatiblePriceLevelsAction;

    constructor(public payload: { platformId: any; }) { }
}

export class PriceLevels implements Action {
    readonly type = CustomerActionTypes.PriceLevels;

    constructor(public payload) { }
}

export class PriceLevelsSuccess implements Action {
    readonly type = CustomerActionTypes.PriceLevelsSuccess;

    constructor(public payload: any) { }
}

export type CustomerActions =
    LoadCustomers
    | CustomersLoaded
    | LoadCustomerLeads
    | CustomerLeadsLoaded
    | CustomerDetails
    | CustomerDetailsSuccess
    | UpdateCustomerDetails
    | UpdateCustomerDetailsSuccess
    | LoadCustomerInvites
    | LoadCustomerInvitesSuccess
    | SendInvitation
    | SendInvitationSuccess
    | DeleteInvitationSuccess
    | DeleteInvitation
    | SaveLeadsStatus
    | SaveLeadsStatusSuccess
    | SendInvitationFailed
    | ClearCustomerDetails
    | ClearCustomers
    | ClearCustomerLeads
    | LoadCompatiblePriceLevels
    | ClearCustomerInvites
    | PriceLevels
    | PriceLevelsSuccess;
