import { Component, OnInit, Input, OnDestroy, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { LoadCountries, LoadStates, LoadTimezones } from '../../actions/utils.actions';
import { selectStates, selectCountries, selectTimezones } from '../../selectors/utils.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { AddSite, UpdateSite } from '../../actions/site.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToggleInfobar } from '../../actions/infobar.actions';
import { InfobarState } from '../../reducers/infobar.reducer';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';
import { selectGeneralLoadingSite } from '../../selectors/site.selector';
import { SaveSiteModel } from 'src/app/core/models/site/save-site.model';
import { GeoLocationService } from 'src/app/core/services/geo-location/geo-location.service';
import { CountryEnum } from '../../enums/country.enum';
import { SelectItemModel } from '../../models/select-item.model';
import { GeoAddressModel } from 'src/app/core/models/location/address.model';
import { TimezoneModel } from 'src/app/core/models/location/timezone.model';
import { SelectConfigs } from '../../models/select-configs.model';

@Component({
    selector: 'app-add-location',
    templateUrl: './add-location.component.html',
    styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit, OnDestroy {

    @Input() infobarConfigs: InfobarState;

    formAddL: FormGroup;
    site: any;
    timezones: Array<TimezoneModel>;
    locationsSA$: Observable<any[]>;
    locationsBA$: Observable<any[]>;
    billingCountryId: number;
    shippingCountryId: number;
    isBillingAddressSameAsShipping = false;
    loading$: Observable<boolean>;

    availableCountries: Array<SelectItemModel>;
    availableShippingStates: Array<SelectItemModel>;
    availableBillingStates: Array<SelectItemModel>;
    selectedShippingCountry: SelectItemModel;
    selectedBillingCountry: SelectItemModel;
    selectedShippingState: SelectItemModel;
    selectedBillingState: SelectItemModel;
    selectedBillingCountryId: string;

    selectConfigBase: SelectConfigs = {
        showSearch: false,
        enableAdd: false,
        searchPlaceholder: 'Search'
    };

    selectCountryDDConfigs: SelectConfigs = {
        ...this.selectConfigBase,
        placeholder: 'Country'
    };

    selectStateDDConfigs: SelectConfigs = {
        ...this.selectConfigBase,
        placeholder: 'State'
    };

    dropdownSettings = {
        text: '',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2,
        singleSelection: true,
        enableFilterSelectAll: false,
        showCheckbox: false
    };

    ip: string;
    shippingGeoLocationObject: GeoAddressModel;
    billingGeoLocationObject: GeoAddressModel;
    destroySubscription$: Subject<void> = new Subject<void>();
    inputShippingAddressValue: string;
    inputBillingAddressValue: string;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        private geoLocationService: GeoLocationService,
        private zone: NgZone,
    ) {
    }

    ngOnInit() {
        this.site = this.infobarConfigs.params[InfobarComponents.SITE];
        this.store.dispatch(new LoadCountries());
        this.store.dispatch(new LoadTimezones());

        if (this.site) {
            this.inputShippingAddressValue = this.site.shippingAddress.address1;
            this.inputBillingAddressValue = this.site.billingAddress.address1;

            this.selectedShippingState = {
                id: this.site.shippingAddress.stateId,
                itemName: this.site.shippingAddress.stateName
            };

            this.selectedBillingState = {
                id: this.site.billingAddress.stateId,
                itemName: this.site.billingAddress.stateName
            };

            this.store.dispatch(new LoadStates({
                countryId: this.site.shippingAddress.countryId
            }));
            this.store.dispatch(new LoadStates({
                countryId: this.site.billingAddress.countryId
            }));

            this.selectStatesByCountry(this.site.shippingAddress.countryId, this.destroySubscription$)
                .subscribe(states => {
                    this.availableShippingStates = this.getAvailableStates(states, this.site.shippingAddress.countryId);
                });

            this.selectStatesByCountry(this.site.billingAddress.countryId, this.destroySubscription$)
                .subscribe(states => {
                    this.availableBillingStates = this.getAvailableStates(states, this.site.billingAddress.countryId);
                });
        }

        this.store.select(selectCountries)
            .pipe(
                filter(countries => countries && countries.length),
                takeUntil(this.destroySubscription$)
            ).subscribe(countries => {
                this.availableCountries = countries.map(country => ({
                    id: country.id,
                    itemName: country.name
                }));

                if (this.site) {
                    this.selectedShippingCountry =
                        this.availableCountries.find(country => country.id === this.site.shippingAddress.countryId);

                    this.selectedBillingCountry =
                        this.availableCountries.find(country => country.id === this.site.billingAddress.countryId);
                }
            });

        this.createForm();
        this.store.select(selectTimezones)
            .pipe(
                filter(timezones => timezones && timezones.length),
                takeUntil(this.destroySubscription$)
            ).subscribe(timezones => {
                this.timezones = timezones.map(timezone => ({
                    ...timezone,
                    utcOffset: this.getUTCOffset(timezone.utcn)
                }));
            });

        // TO DO; after endpoint
        this.locationsSA$ = of([0, 21176, 21178]);
        this.locationsBA$ = of([0, 21177, 21179]);

        this.loading$ = this.store.select(selectGeneralLoadingSite);
    }

    getUTCOffset(utcn: string): number {
        const utcTime = utcn.split('UTC')[1].split(':');
        const utcOffset = Math.abs(+utcTime[0]) * 60 + +utcTime[1];
        return utcn.includes('-') ? -utcOffset : utcOffset;
    }

    ngOnDestroy(): void {
        this.destroySubscription$.next();
        this.destroySubscription$.unsubscribe();
    }

    createForm() {
        this.formAddL = this.fb.group({
            id: [this.site ? this.site.id : null, []],
            name: [this.site ? this.site.name : null, [Validators.required]],
            timezoneId: [this.site ? this.site.timezoneId : null, [Validators.required]],
            shippingAddress: this.fb.group({
                existingSA: [null],
                address1: [this.site ? this.site.shippingAddress.address1 : null, [Validators.required]],
                city: [this.site ? this.site.shippingAddress.city : null],
                zipCode: [this.site ? this.site.shippingAddress.zipCode : null,
                [Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
                countryId: [this.site ? this.site.shippingAddress.countryId : '', [Validators.required]],
                stateId: [this.site ? this.site.shippingAddress.stateId : '', [Validators.required]],
            }),
            billingAddress: this.fb.group({
                existingBA: [null],
                address1: [this.site ? this.site.billingAddress.address1 : null, [Validators.required]],
                city: [this.site ? this.site.billingAddress.city : null],
                zipCode: [this.site ? this.site.billingAddress.zipCode : null,
                [Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
                countryId: [this.site ? this.site.billingAddress.countryId : '', [Validators.required]],
                stateId: [this.site ? this.site.billingAddress.stateId : '', [Validators.required]],
            }),
        });

        this.shippingAddress.existingSA.valueChanges
            .pipe(
                filter(value => value !== null && value !== undefined),
                takeUntil(this.destroySubscription$)
            )
            .subscribe((shippingValue) => {
                if (shippingValue === 0) {
                    this.shippingAddress.address1.setValidators(Validators.required);
                    this.shippingAddress.city.setValidators(Validators.required);
                    this.shippingAddress.zipCode.setValidators([Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]);
                    this.shippingAddress.countryId.setValidators(Validators.required);
                    this.shippingAddress.stateId.setValidators(Validators.required);
                } else {
                    this.shippingAddress.address1.setValidators(null);
                    this.shippingAddress.city.setValidators(null);
                    this.shippingAddress.zipCode.setValidators(null);
                    this.shippingAddress.countryId.setValidators(null);
                    this.shippingAddress.stateId.setValidators(null);
                }

                this.shippingAddress.address1.updateValueAndValidity({ onlySelf: true });
                this.shippingAddress.city.updateValueAndValidity({ onlySelf: true });
                this.shippingAddress.zipCode.updateValueAndValidity({ onlySelf: true });
                this.shippingAddress.countryId.updateValueAndValidity({ onlySelf: true });
                this.shippingAddress.stateId.updateValueAndValidity({ onlySelf: true });
            });

        this.billingAddress.existingBA.valueChanges
            .pipe(
                filter(value => value !== null && value !== undefined),
                takeUntil(this.destroySubscription$)
            )
            .subscribe((billlingValue) => {
                this.changeBillingAddressValidators(billlingValue === 0);
            });
    }

    getAvailableStates(data: Array<any>, countryId: number): Array<SelectItemModel> {
        return data.map(state => ({
            id: state.id,
            itemName: state.name,
            abreviation: state.abreviation,
            countryId
        }));
    }

    updateSelectedCountry(countryId: number, isShippingChanged?: boolean): void {
        if (isShippingChanged) {
            this.shippingAddress.countryId.setValue(countryId);
            this.shippingAddress.stateId.reset();
            this.selectedShippingCountry = this.availableCountries.find(country => country.id === countryId);
            this.selectedShippingState = null;
        } else {
            this.billingAddress.countryId.setValue(countryId);
            this.billingAddress.stateId.reset();
            this.selectedBillingCountry = this.availableCountries.find(country => country.id === countryId);
            this.selectedBillingState = null;
        }

        this.selectStatesByCountry(countryId, this.destroySubscription$)
            .subscribe(data => {
                if (data) {
                    if (isShippingChanged) {
                        this.availableShippingStates = this.getAvailableStates(data, countryId);
                        // TODO - if we need to keep the state selection based on geolocation if country is changed
                        // this.selectedShippingState = this.availableShippingStates.find(shippingState =>
                        //     shippingState.abreviation === this.shippingGeoLocationObject?.state);

                        // this.shippingAddress.stateId.setValue(this.selectedShippingState?.id);
                    } else {
                        this.availableBillingStates = this.getAvailableStates(data, countryId);
                        // TODO - if we need to keep the state selection based on geolocation if country is changed
                        // this.selectedBillingState =
                        //     this.availableBillingStates.find(billingState =>
                        //         billingState.abreviation === this.billingGeoLocationObject?.state);
                        // this.billingAddress.stateId.setValue(this.selectedBillingState?.id);
                    }
                }
            });

        this.store.dispatch(new LoadStates({
            countryId
        }));
    }

    updateSelectedState(stateId: number, isShippingChanged?: boolean): void {
        if (isShippingChanged) {
            this.shippingAddress.stateId.setValue(stateId);
            this.selectedShippingState = this.availableShippingStates.find(shippingState =>
                shippingState.id === stateId);
        } else {
            this.billingAddress.stateId.setValue(stateId);
            this.selectedBillingState = this.availableBillingStates.find(billingState =>
                billingState.id === stateId);
        }
    }

    onSelectShippingPlace(place: any): void {
        this.geoLocationService.getAddressFromPlaces(place).then((data: GeoAddressModel) => {
            this.shippingGeoLocationObject = {
                ...data,
                address1: place.formatted_address,
                googleLocationId: place.place_id,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            };

            this.zone.run(() => {
                const subscription = new Subject<void>();
                const ipCountry = CountryEnum[this.shippingGeoLocationObject.country];

                this.loadStatesByCountry(ipCountry);

                const addressTimezone = this.timezones.find(timezone => timezone.utcOffset === this.shippingGeoLocationObject.utcOffset);
                this.selectedShippingCountry = this.availableCountries
                    .find(country => country.id === ipCountry);

                this.form.timezoneId.setValue(addressTimezone.id);
                this.shippingAddress.countryId.setValue(ipCountry);
                this.shippingAddress.address1.setValue(this.shippingGeoLocationObject.address1);
                this.shippingAddress.zipCode.setValue(this.shippingGeoLocationObject.zipCode);
                this.shippingAddress.city.setValue(this.shippingGeoLocationObject.city);
                this.selectedShippingState = null;
                this.shippingAddress.stateId.setValue(null);

                this.selectStatesByCountry(ipCountry, subscription)
                    .subscribe(states => {
                        this.availableShippingStates = this.getAvailableStates(states, ipCountry);

                        if (this.availableShippingStates.length && !this.selectedShippingState) {
                            this.selectedShippingState =
                                this.availableShippingStates.find(shippingState =>
                                    shippingState.abreviation === this.shippingGeoLocationObject.state)
                                ;

                            this.shippingAddress.stateId.setValue(this.selectedShippingState.id);
                        }

                        subscription.next();
                        subscription.unsubscribe();
                    });
            });
        });
    }

    onSelectBillingPlace(place: any): void {
        this.geoLocationService.getAddressFromPlaces(place).then((data: GeoAddressModel) => {
            this.billingGeoLocationObject = {
                ...data,
                address1: place.formatted_address,
                googleLocationId: place.place_id,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            };
            const subscription = new Subject<void>();
            const ipCountry = CountryEnum[this.billingGeoLocationObject.country];

            this.loadStatesByCountry(ipCountry);

            this.selectedBillingCountry = this.availableCountries
                .find(country => country.id === ipCountry);
            this.billingAddress.countryId.setValue(ipCountry);
            this.billingAddress.address1.setValue(this.billingGeoLocationObject.address1);
            this.billingAddress.zipCode.setValue(this.billingGeoLocationObject.zipCode);
            this.billingAddress.city.setValue(this.billingGeoLocationObject.city);
            this.selectedBillingState = null;
            this.billingAddress.stateId.setValue(null);

            this.selectStatesByCountry(ipCountry, subscription)
                .subscribe(states => {
                    this.availableBillingStates = this.getAvailableStates(states, ipCountry);

                    if (this.availableBillingStates.length && !this.selectedBillingState) {
                        this.selectedBillingState =
                            this.availableBillingStates.find(shippingState =>
                                shippingState.abreviation === this.billingGeoLocationObject?.state);

                        this.billingAddress.stateId.setValue(this.selectedBillingState.id);
                    }

                    subscription.next();
                    subscription.unsubscribe();
                });
        });
    }

    loadStatesByCountry(countryId: number): void {
        this.store.dispatch(new LoadStates({
            countryId
        }));
    }

    private selectStatesByCountry(countryId: number, subscription: Subject<void>): Observable<any> {
        return this.store.select(selectStates, { countryId })
            .pipe(
                filter(states => states && states.length),
                takeUntil(subscription)
            );
    }

    clearAddressSelection(isShippingAddress?: boolean): void {
        if (isShippingAddress) {
            this.resetAddress('shippingAddress');
            this.selectedShippingCountry = null;
            this.selectedShippingState = null;
            this.form.timezoneId.reset();
        } else {
            this.resetAddress('billingAddress');
            this.selectedBillingCountry = null;
            this.selectedBillingState = null;
        }
    }

    resetAddress(controlKey: string): void {
        const control = (this.formAddL.get(controlKey) as FormGroup).controls;
        control.city.reset();
        control.zipCode.reset();
        control.countryId.reset();
        control.stateId.reset();
    }

    get form() { return this.formAddL.controls; }

    get shippingAddress() { return (this.formAddL.controls.shippingAddress as FormGroup).controls; }

    get billingAddress() { return (this.formAddL.controls.billingAddress as FormGroup).controls; }


    onSubmit() {
        if (this.isBillingAddressSameAsShipping) {
            this.form.billingAddress.patchValue(this.form.shippingAddress.value);
        }

        if (this.formAddL.invalid) {
            return;
        }

        let billingAddressObj = null;
        let shippingAddressObj = null;

        if (this.shippingAddress.existingSA.value === 0 || this.shippingAddress.existingSA.value === null) {
            shippingAddressObj = {
                ...this.formAddL.value.shippingAddress
            };
        }
        if (this.billingAddress.existingBA.value === 0 || this.billingAddress.existingBA.value === null) {
            billingAddressObj = {
                ...this.formAddL.value.billingAddress
            };
        }

        const site: SaveSiteModel = {
            ...this.formAddL.value,
            shippingAddressId: this.shippingAddress.existingSA.value,
            shippingAddress: shippingAddressObj,
            billingAddressId: this.billingAddress.existingBA.value,
            billingAddress: billingAddressObj
        };

        if (this.form.id.value) {
            this.store.dispatch(new UpdateSite({ site }));
        } else {
            this.store.dispatch(new AddSite({ site }));
        }
    }

    onSameBillingAddressChange($event: MatCheckboxChange) {
        this.isBillingAddressSameAsShipping = $event.checked;
        this.changeBillingAddressValidators(!this.isBillingAddressSameAsShipping);
    }

    onCancel() {
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

    private changeBillingAddressValidators(applyDefaultValidators: boolean) {
        if (applyDefaultValidators) {
            this.billingAddress.address1.setValidators(Validators.required);
            this.billingAddress.city.setValidators(Validators.required);
            this.billingAddress.zipCode.setValidators([Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]);
            this.billingAddress.countryId.setValidators(Validators.required);
            this.billingAddress.stateId.setValidators(Validators.required);
        } else {
            this.availableBillingStates = [];
            this.billingGeoLocationObject = null;
            this.billingAddress.address1.clearValidators();
            this.billingAddress.city.clearValidators();
            this.billingAddress.zipCode.clearValidators();
            this.billingAddress.countryId.clearValidators();
            this.billingAddress.stateId.clearValidators();
            this.form.billingAddress.reset();
        }

        this.formAddL.updateValueAndValidity();
    }

}
