import { createSelector } from '@ngrx/store';

export const selectSearchState = state => state.search;

export const selectSearchKeyword = createSelector(
    selectSearchState,
    state => state.keyword
);

export const selectShowSearch = createSelector(
    selectSearchState,
    state => state.show
);

export const selectShowOnlyFilter = createSelector(
    selectSearchState,
    state => state.show && !state.searchUrl
);

export const selectSearchText = createSelector(
    selectSearchState,
    state => state.searchText
);

export const selectShowOrderGuide = createSelector(
    selectSearchState,
    state => state.showOrderGuide
)