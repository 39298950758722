import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { ProductGroupModel } from '../../models/product/product-group.model';
import { PriceLevelModel } from '../../models/product/price-level.model';
import { ProductCatalogFilter } from '../../models/product/product-catalog.filter';
import { ProductSettingsModel } from '../../models/product/product-settings.model';
import { SaveProductCatalogModel } from '../../models/product/save-product.model';
import { ProductSaveRequestModel } from '../../models/product/product-save-request.model';
import { ConvertedPriceModel } from '../../models/product/converted-price.model';
import { ProductCatalogModel } from '../../models/product/product-catalog.model';
import { ProductUploadModel } from '../../models/product/product-upload.model';
import { SaveUploadModel } from '../../models/product/save-upload.model';
import { ProductParCountModel } from '../../models/product/product-par-count.model';
import { ProductInventorySettingsModel } from '../../models/product/product-inventory-settings.model';
import { ProductCompositionModel } from '../../models/product/product-composition.model';
import { GeneralItemModel } from 'src/app/shared/models/general-item.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(
        private http: HttpClient
    ) { }

    setProductFavorite(productId: number, isFavorite: boolean, customerAccountId?: number, customerSiteId?: number) {
        return this.http.post(`${environment.api.content}/Products/${productId}/set_favorite`, {
            isFavorite, customerAccountId, customerSiteId
        });
    }

    setProductVendorFavorite(productId: number, vendorId: number, isFavorite: boolean) {
        return this.http.post(`${environment.api.content}/Products/${productId}/set_favorite/${vendorId}`, {
            isFavorite
        });
    }

    changeName(productId: number, name: string) {
        return this.http.post(`${environment.api.content}/Products/${productId}/set_custom_name`, {
            customName: name
        });
    }

    @Cacheable()
    loadPuchaseHistory(products: number[]) {
        return this.http.post(`${environment.api.content}/Products/purchase_history`, {
            ids: products
        });
    }

    getProductDetails(productId: number) {
        return this.http.get(`${environment.api.content}/Products/${productId}`);
    }

    @Cacheable()
    getRelatedProducts(productId: number) {
        return this.http.get(`${environment.api.content}/Products/${productId}/related_products`);
    }

    @Cacheable()
    getPriceTrends(productId: number) {
        return this.http.get(`${environment.api.content}/Products/${productId}/price_trend`);
    }

    saveProductGroup(productGroup: ProductGroupModel) {
        if (productGroup.deleted) {
            productGroup = {
                productGroupId: productGroup.productGroupId,
                deleted: productGroup.deleted
            };
        }
        return this.http.post(`${environment.api.content}/Products/Group/save_group`, productGroup);
    }

    getProductGroup(productGroupId: number) {
        return this.http.get(`${environment.api.content}/Products/Group/${productGroupId}`);
    }

    getCategoriesSearch() {
        return this.http.get(`${environment.api.content}/Category/search`);
    }

    getAllCategories() {
        return this.http.get(`${environment.api.content}/Category/get_all_categories`);
    }

    getProducts(filter: ProductCatalogFilter) {
        return this.http.post(`${environment.api.content}/Products/catalog`, filter);
    }

    savePriceLevel(priceLevel: PriceLevelModel) {
        return this.http.post(`${environment.api.content}/Products/catalog/priceLevel`, priceLevel);
    }

    updatePriceLevel(priceLevel: PriceLevelModel) {
        return this.http.put(`${environment.api.content}/Products/catalog/priceLevel/${priceLevel.id}`, priceLevel);
    }

    saveProductSettings(productSettings: ProductSettingsModel) {
        return this.http.put(`${environment.api.content}/Products/catalog/${productSettings.productId}/settings`, productSettings);
    }

    saveProduct(product: SaveProductCatalogModel) {
        return this.http.put(`${environment.api.content}/Products/catalog/${product.id}`, product);
    }

    loadPriceLevelsForProduct(productId: number) {
        return this.http.get(`${environment.api.content}/Products/catalog/${productId}/priceLevels`);
    }

    savePriceLevelsForProduct(productCatalog: ProductCatalogModel) {
        return this.http.put(`${environment.api.content}/Products/catalog/${productCatalog.id}/save_priceLevels`, productCatalog);
    }

    getBaseCatalogs() {
        return this.http.get(`${environment.api.content}/Products/catalog/getBaseCatalogs`);
    }

    getCategoryOptions() {
        return this.http.get(`${environment.api.content}/Products/catalog/loadCategoryOptions`);
    }

    getUomOptions() {
        return this.http.get(`${environment.api.content}/Products/catalog/loadUomOptions`);
    }

    saveProductCatalog(requestModel: ProductSaveRequestModel) {
        const formData = new FormData();

        formData.append('file', requestModel.file);
        formData.append('product', JSON.stringify(requestModel.product));

        return this.http.put(`${environment.api.content}/Products/catalog/add`, formData);
    }

    saveProductForComposition(requestModel: ProductSaveRequestModel) {
        const formData = new FormData();

        formData.append('file', requestModel.file);
        formData.append('product.name', requestModel.product.name);
        formData.append('product.sku', requestModel.product.vendorSku);
        formData.append('product.description', requestModel.product.shortDescription);
        formData.append('product.uomId', requestModel.product.unitUomId.toString());
        formData.append('product.uomSize', requestModel.product.unitUomSize.toString());
        formData.append('product.packUomId', requestModel.product.unitUomId.toString());
        formData.append('product.packUomSize', requestModel.product.uomSize.toString());
        formData.append('product.categoryId', requestModel.product.categoryId.toString());

        return this.http.post(`${environment.api.content}/Products/add`, formData);
    }

    uploadProductsPrices(requestModel: ProductUploadModel) {
        const formData = new FormData();

        formData.append('file', requestModel.file);
        formData.append('entityId', JSON.stringify(requestModel.entityId));
        formData.append('entityTypeId', JSON.stringify(requestModel.entityTypeId));

        return this.http.post(`${environment.api.content}/Products/catalog/upload_prices`, formData);
    }

    saveUploadProductsPrices(saveUploadModel: SaveUploadModel) {
        return this.http.post(`${environment.api.content}/Products/catalog/upload_prices_complete`, saveUploadModel);
    }

    getConvertedPrice(convertedPrice: Array<ConvertedPriceModel>) {
        return this.http.post(`${environment.api.content}/Products/catalog/getConvertedPrice`, { priceLevels: convertedPrice });
    }

    getRelatedUom(uomId: number) {
        return this.http.get(`${environment.api.content}/Products/catalog/getRelatedUom/${uomId}`);
    }

    getPriceLevels() {
        return this.http.post(`${environment.api.content}/Products/catalog/getPriceLevels`, undefined);
    }

    exportProducts(filter: ProductCatalogFilter) {
        return this.http.post(`${environment.api.content}/Products/export_price`, filter, {
            responseType: 'blob'
        });
    }

    addProductCount(WarehouseId: number, locationId: number, count: ProductParCountModel) {
        return this.http.post(`${environment.api.content}/Warehouse/${WarehouseId}/${locationId}/add_count`, count);
    }

    saveProductInventorySettings(WarehouseId: number, locationId: number, settings: ProductInventorySettingsModel) {
        return this.http.post(`${environment.api.content}/Warehouse/${WarehouseId}/${locationId}/save_product_settings`, settings);
    }

    addProductPar(WarehouseId: number, locationId: number, parTemplateId: number, par: ProductParCountModel) {
        return this.http.post(`${environment.api.content}/Warehouse/${WarehouseId}/${locationId}/${parTemplateId}/add_par`, par);
    }

    convertProductUom(uomId: number, uomSize: number, productId: number) {
        return this.http.post(`${environment.api.content}/Products/catalog/${productId}/convert`, { uomId, uomSize });
    }

    getProductsGlobalList(keyword: string, excludedProductsIds?: Array<number>) {
        return this.http.post(`${environment.api.content}/Products/global_search`, { keyword, excludedProductsIds });
    }

    createComposition(productComposition: ProductCompositionModel) {
        return this.http.post(`${environment.api.content}/Products/composition/create_composition`, productComposition);
    }

    editComposition(productComposition: ProductCompositionModel) {
        return this.http.put(`${environment.api.content}/Products/composition/edit_composition`, productComposition);
    }

    deleteComposition(productCompositionId: number) {
        return this.http.delete(`${environment.api.content}/Products/composition/${productCompositionId}`);
    }

    loadProductComposition(productId: number, productCompositionId: number) {
        return this.http.post(`${environment.api.content}/Products/composition/load_composition`, { productId, productCompositionId });
    }

    getManufacturers(): Observable<Array<GeneralItemModel>> {
        return this.http.get<Array<GeneralItemModel>>(`${environment.api.content}/Products/get_manufacturers`);
    }
}
