import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/shared/validators/mustmatch.validator';
import { ChangePasswordModel } from 'src/app/core/models/change-password.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import * as authActions from '../../actions/auth.actions';
import { InfobarState } from '../../reducers/infobar.reducer';
import { getLoading } from '../../selectors/auth.selector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

    @Input() infobarConfigs: InfobarState;
    changePasswordForm: FormGroup;
    loading: boolean;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private formBuilder: FormBuilder, private store: Store<AppState>) { }

    ngOnInit() {
        this.changePasswordForm = this.formBuilder.group(
            {
                currentPassword: [''],
                newPassword: ['', [Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/)]],
                confirmPassword: ['', [Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/)]]
            },
            {
                validator: MustMatch('newPassword', 'confirmPassword')
            }
        );

        this.store.select(getLoading).pipe(takeUntil(this.ngUnsubscribe)).subscribe(loading => {
            this.loading = loading;

            if (!loading) {
                this.changePasswordForm.reset();
            }
        });
    }

    changePassword() {
        if (this.changePasswordForm.valid) {
            const changePasswordModel = new ChangePasswordModel(this.changePasswordForm.value);
            this.store.dispatch(new authActions.ChangePassword(changePasswordModel));
        }
    }

    get formValid() { return this.changePasswordForm.valid; }

    get form() { return this.changePasswordForm.controls; }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
