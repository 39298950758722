import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-arrow-circle-right',
  templateUrl: './icon-arrow-circle-right.component.html',
  styleUrls: ['./icon-arrow-circle-right.component.scss']
})
export class IconArrowCircleRightComponent {

  @Input() cssClass = 'icon--18';

}
