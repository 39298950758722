import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-cart-summary-temp1',
    templateUrl: './cart-summary.temp1.component.html',
    styleUrls: ['./cart-summary.temp1.component.scss']
})
export class CartSummaryTemp1Component {
    @Input() summary: any;
    @Input() loading: boolean;
    @Output() reloadSummary = new EventEmitter<void>();

    reloadSummaryEvent(){
        this.reloadSummary.emit();
    }

}

