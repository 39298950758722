import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Cacheable } from 'ngx-cacheable';
import { Observable } from 'rxjs';
import { GeneralCartModel } from '../../models/cart/general-cart.model';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe
    ) { }

    loadCartSummary() {
        return this.http.get(`${environment.api.content}/cart/summary`);
    }

    loadCart(): Observable<GeneralCartModel[]> {
        return this.http.get<GeneralCartModel[]>(`${environment.api.content}/cart`);
    }

    deleteCartByVendorId(vendorId: number, shoppingCartItemId?: number, customerSiteId?: number) {
        return this.http.delete(`${environment.api.content}/cart/delete/${vendorId}`, {
            params: {
                shoppingCartItemId: shoppingCartItemId ? shoppingCartItemId.toString() : '',
                customerSiteId: customerSiteId ? customerSiteId.toString() : ''
            }
        });
    }

    saveNotes(notes: string, vendorId: number, shoppingCartItemId?: number, customerSiteId?: number) {
        return this.http.post(`${environment.api.content}/cart/save_notes`, {
            notes: notes.trim(),
            vendorId,
            shoppingCartItemId,
            customerSiteId
        });
    }

    saveDelivery(
        deliveryDate: Date,
        fromTime: Date,
        toTime: Date,
        vendorId: number,
        shippingMethodId: number,
        shippingCharge: number,
        shippingReferenceId?: string,
        customerSiteId?: number
    ) {
        return this.http.post(`${environment.api.content}/cart/save_delivery_info`, {
            deliveryDate: this.datePipe.transform(deliveryDate, 'MM/dd/yyyy'),
            fromTime: this.datePipe.transform(fromTime, 'HH:mm'),
            toTime: this.datePipe.transform(toTime, 'HH:mm'),
            vendorId,
            shippingCharge,
            shippingMethodId,
            deliveryMethodReferenceId: shippingReferenceId,
            customerSiteId
        });
    }

    addToCart(params: {
        productId: number, vendorId: number, quantity: number,
        reloadCartVendor?: boolean, productIdFromGroup?: number, customerSiteId?: number, customerAccountId?: number, price?: number, originalVendorId?: number
    }) {
        return this.http.post(`${environment.api.content}/cart/add_to_cart`, {
            productId: params.productIdFromGroup && params.productIdFromGroup > 0 ? params.productIdFromGroup : params.productId,
            vendorId: params.vendorId,
            quantity: params.quantity,
            customerSiteId: params.customerSiteId,
            customerAccountId: params.customerAccountId,
            price: params.price,
            originalVendorId: params.originalVendorId
        });
    }

    moveProduct(params: { productId: number, toVendorId: number, fromVendorId: number, quantity: number; }) {
        return this.http.post(`${environment.api.content}/cart/move_product`, {
            productId: params.productId,
            fromVendorId: params.fromVendorId,
            toVendorId: params.toVendorId,
            quantity: params.quantity
        });
    }

    loadCartTotals(request: { vendorId: number, customerSiteId?: number, customerAccountId?: number }) {
        return this.http.post(`${environment.api.content}/cart/${request.vendorId}`, { customerSiteId: request.customerSiteId, customerAccountId: request.customerAccountId });
    }

    @Cacheable()
    getCuttoff(vendors: number[]) {
        return this.http.post(`${environment.api.content}/cart/cutOffInfo`, {
            ids: vendors
        });
    }

    hasTipsEnabled(vendorId: number) {
        return this.http.get(`${environment.api.content}/cart/${vendorId}/has_tips_enabled`);
    }

    saveTips(vendorId: number, amount: number) {
        return this.http.post(`${environment.api.content}/cart/${vendorId}/save_tip`, {
            quantity: amount
        });
    }

    loadCartSummaryOrderGuide(request: { customerSiteId: number, customerAccountId: number }) {
        return this.http.post(`${environment.api.content}/cart/summary_order_guide`, request);
    }

}
