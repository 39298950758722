import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { CustomerItemOrderGuide } from 'src/app/core/models/customer-item-order-guide.model';
import { AppUser } from 'src/app/core/models/user.model';
import { LoadCartSummaryOrderGuide, LoadCustomersList, SaveCustomerOrderGuide } from '../../actions/order-guide.action';
import { currentUserDetails } from '../../selectors/auth.selector';
import { customersList } from '../../selectors/order-guide.selector';
import { selectShowOrderGuide } from '../../selectors/search.selector';

@Component({
  selector: 'app-order-guide-select',
  templateUrl: './order-guide-select.component.html',
  styleUrls: ['./order-guide-select.component.scss']
})
export class OrderGuideSelectComponent implements OnInit {

  items: CustomerItemOrderGuide[];
  selectedItem: CustomerItemOrderGuide;
  currentUser: AppUser;
  showCustomers = false;
  showComponent = false;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.store.select(selectShowOrderGuide).subscribe(show => {
      this.showComponent = show;
    })
    this.store.select(currentUserDetails).subscribe((response) => {
      this.currentUser = response;
      this.store.dispatch(new LoadCustomersList(this.currentUser?.userId));
      this.store.select(customersList).subscribe((response) => {
        this.items = response;
        this.selectedItem = this.items?.find(i => i.selected === true);
        if (this.selectedItem) {
          this.store.dispatch(new LoadCartSummaryOrderGuide({ customerSiteId: this.selectedItem.buyerCustomerSiteId, customerAccountId: this.selectedItem.buyerCustomerAccountId }));
        }
      })
    })
  }

  updateSelectedItem(customer: CustomerItemOrderGuide): void {
    this.selectedItem = customer;
    const customerItem = new CustomerItemOrderGuide();
    customerItem.buyerCustomerSiteId = this.selectedItem.buyerCustomerSiteId;
    customerItem.buyerCustomerName = this.selectedItem.buyerCustomerName;
    customerItem.selected = true;
    customerItem.buyerCustomerAccountId = this.selectedItem.buyerCustomerAccountId;
    customerItem.buyerAcronym = this.selectedItem.buyerAcronym;
    this.store.dispatch(new SaveCustomerOrderGuide({ vendorCustomerId: this.currentUser?.userId, request: customerItem }));
    this.router.navigate(['customers', 'order-guide']);
  }

  customersOpened(isOpen: boolean): void {
    this.showCustomers = isOpen;
  }
}
