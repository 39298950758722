import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { SearchActionTypes, SearchByKeywordGlobal } from '../actions/search.actions';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { selectSearchState } from '../selectors/search.selector';
import { SearchState } from '../reducers/search.reducer';

@Injectable()
export class SearchEffects {

    @Effect({ dispatch: false })
    search$ = this.actions$.pipe(
        ofType<SearchByKeywordGlobal>(SearchActionTypes.SearchByKeywordGlobalAction),
        withLatestFrom(this.store.select(selectSearchState)),
        tap(([action, searchDetails]: [any, SearchState]) => {
            if (searchDetails.searchUrl) {
                this.router.navigate([searchDetails.searchUrl], {
                    queryParams: {
                        page: 1,
                        keyword: action.payload.keyword,
                        vendors: searchDetails.params.vendors,
                        isMasterCategory: searchDetails.params.isMasterCategory,
                        categoryId: searchDetails.params.categoryId,
                        viewModes: searchDetails.params.viewModes,
                        sort: searchDetails.params.sort,
                        showInactive: searchDetails.params.showInactive,
                        showExpired: searchDetails.params.showExpired,
                        priceLevelId: searchDetails.params.priceLevelId
                    },
                    queryParamsHandling: 'merge'
                });
            }
        })
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<AppState>
    ) { }
}
