import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { currentCompanyType, currentUserDetails } from '../../selectors/auth.selector';
import { AppState } from 'src/app/app.reducer';
import { UpdateSelectedSite } from '../../actions/auth.actions';
import { sitesList } from '../../selectors/site.selector';
import { ToggleInfobar } from '../../actions/infobar.actions';
import { InfobarComponents, InfobarLocationComponents } from 'src/app/core/enums/infobar-components.enum';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';

@Component({
    selector: 'app-location-selection',
    templateUrl: './location-selection.component.html',
    styleUrls: ['./location-selection.component.scss']
})
export class LocationSelectionComponent implements OnInit {

    showLocations = false;

    sites$: Observable<any[]>;

    selectedSite: any;

    searchText: string = null;

    template$: Observable<TemplateEnum>;
    tempalteEnum = TemplateEnum;

    currentCompanyType$: Observable<COMPANY_TYPE>;
    CompanyType = COMPANY_TYPE;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.currentCompanyType$ = this.store.select(currentCompanyType);
        this.template$ = this.store.select(getThemeId);
        this.store.pipe(select(currentUserDetails)).subscribe((userDetails) => {
            this.sites$ = this.store.select(sitesList).pipe(
                tap((sites: any[]) => {
                    const siteIdStorage = JSON.parse(localStorage.getItem('SITE_ID_STORAGE'));

                    if (siteIdStorage && siteIdStorage !== userDetails.defaultSiteId) {
                        userDetails.currentSiteId = siteIdStorage;
                        this.selectedSite = sites.find(s => s.id === userDetails.currentSiteId);
                    } else {
                        this.selectedSite = sites.find(s => s.id === userDetails.defaultSiteId);
                    }

                    if (this.selectedSite) {
                        this.selectedSite = this.selectedSite;
                    } else {
                        if (sites.length) {
                            this.selectedSite = sites[0];
                            this.store.dispatch(new UpdateSelectedSite({
                                siteId: this.selectedSite.id,
                                companyTypeId: this.selectedSite.companyTypeId
                            }));
                        }
                    }
                })
            );
        });
    }

    locationsOpened(isOpen) {
        this.showLocations = isOpen;
        this.searchText = null;
    }

    preventPopupClose(e) {
        e.stopPropagation();
    }

    updateSelectedSite(site) {
        if (site.id === this.selectedSite.id) {
            return;
        }

        this.store.dispatch(new UpdateSelectedSite({ siteId: site.id, companyTypeId: site.companyTypeId }));
        this.selectedSite = site;
    }

    onAddNewSite() {
        this.store.dispatch(new ToggleInfobar({
            open: true,
            title: `New Location`,
            params: {
                [InfobarLocationComponents.PAGE_NAME]: true,
                [InfobarComponents.SITE]: null
            }
        }));
    }
}
