import { PlatformType } from './../../../../environments/platform-type.enum';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-marketing',
  templateUrl: './icon-marketing.component.html',
  styleUrls: ['./icon-marketing.component.scss']
})
export class IconMarketingComponent implements OnInit {

  @Input() cssClass = '';

  currentPlatform = environment.platformId;
  PlatformType = PlatformType;

  constructor() { }

  ngOnInit(): void {
  }

}
