import { Component, OnInit, Input } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { LoadCountries, LoadStates } from '../../actions/utils.actions';
import { takeUntil } from 'rxjs/operators';
import { selectStates, selectCountries } from '../../selectors/utils.selector';
import { ToggleInfobar } from '../../actions/infobar.actions';
import { ADDRESS_TYPE } from 'src/app/core/enums/address-type.enum';
import { UpdateAddress } from '../../actions/site.actions';
import { selectLoadingUpdateAddress } from '../../selectors/site.selector';
import { InfobarState } from '../../reducers/infobar.reducer';
import { AddressModel } from 'src/app/core/models/customer/address.model';

@Component({
    selector: 'app-edit-address',
    templateUrl: './edit-address.component.html',
    styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit {
    @Input() infobarConfigs: InfobarState;
    address: AddressModel;
    @Input() addressType: ADDRESS_TYPE;

    formEditAddress: FormGroup;
    loading$: Observable<boolean>;
    selectedState: Array<any> = [];
    selectedCountry: Array<any> = [];
    availableStates: Array<any>;
    availableCountries: Array<any>;

    dropdownSettings = {
        text: '',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2,
        singleSelection: true,
        enableFilterSelectAll: false,
        showCheckbox: false
    };

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>) {
    }

    ngOnInit() {
        this.address = this.infobarConfigs.params.address;
        this.addressType = this.infobarConfigs.params.addressType;
        this.loading$ = this.store.select(selectLoadingUpdateAddress);
        this.store.select(selectCountries).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            if (data) {
                this.availableCountries = data.map(country => ({
                    id: country.id,
                    itemName: country.name
                }));

                this.selectedCountry = this.availableCountries.filter(country => country.id === this.address.countryId);

                this.store.select(selectStates, {
                    countryId: this.address.countryId
                }).pipe().subscribe((states) => {
                    if (states) {
                        this.availableStates = states.map(state => ({
                            id: state.id,
                            itemName: state.name
                        }));

                        this.selectedState = this.availableStates.filter(state => state.id === this.address.stateId);
                    }
                });
            }
        });
        this.store.dispatch(new LoadCountries());

        this.createForm();
    }

    createForm() {
        this.formEditAddress = this.fb.group({
            id: [this.address.id],
            firstName: [this.address.firstName],
            lastName: [this.address.lastName],
            email: [this.address.email, [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
            phone: [this.address.phone, [Validators.pattern(/^[0-9]*$/), Validators.minLength(10), Validators.maxLength(10)]],
            address1: [this.address.address1],
            city: [this.address.city],
            zipCode: [this.address.zipCode, [Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
            countryId: [this.address.countryId, [Validators.required]],
            stateId: [this.address.stateId, [Validators.required]]
        });

        this.form.countryId.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
            if (value.length > 0) {
                this.store.select(selectStates, {
                    countryId: value[0].id
                }).pipe().subscribe(data => {
                    if (data) {
                        this.availableStates = data.map(state => ({
                            id: state.id,
                            itemName: state.name
                        }));
                    }
                });

                this.store.dispatch(new LoadStates({
                    countryId: value[0].id
                }));

                this.form.stateId.setValue(null);
                this.form.stateId.updateValueAndValidity({ onlySelf: true });
            }
        });

        this.formEditAddress.valueChanges.subscribe(() => {
            this.formEditAddress.markAllAsTouched();
        });
    }

    get form() { return this.formEditAddress.controls; }

    onSubmit() {
        if (this.formEditAddress.invalid) {
            return;
        }

        const address: AddressModel = {
            ...this.formEditAddress.value
        };

        address.stateId = this.formEditAddress.value.stateId[0].id;
        address.countryId = this.formEditAddress.value.countryId[0].id;

        this.store.dispatch(new UpdateAddress({
            address,
            addressType: this.addressType
        }));
    }

    onCancel() {
        this.formEditAddress.reset();
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

}
