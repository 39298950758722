import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icon-vegetables',
    templateUrl: './icon-vegetables.component.html',
    styleUrls: ['./icon-vegetables.component.scss']
})
export class IconVegetablesComponent {

    @Input() cssClass = 'icon--18';

}
