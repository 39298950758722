import { Action } from '@ngrx/store';
import { UserActions, UserActionTypes } from '../actions/user.actions';
import { randomColor } from '../constants/global.contants';
import { EMAIL_SETTING_TYPE } from 'src/app/core/enums/email-setting-type.enum';

export interface UserState {
    users: any[];
    loadingUsers: boolean;
    user: any;
    customerUsers: Array<any>;
}

export const initialState: UserState = {
    users: [],
    loadingUsers: false,
    user: undefined,
    customerUsers: []
};

export function userReducer(state = initialState,
    action: UserActions): UserState {
    let newUser = null;
    switch (action.type) {
        case UserActionTypes.LoadUsersAction:
            return {
                ...state,
                loadingUsers: true
            };
        case UserActionTypes.LoadUsersSuccess:
            return {
                ...state,
                users: action.payload.users.map(s => ({
                    ...s,
                    randomColor: randomColor()
                })),
                loadingUsers: false
            };
        case UserActionTypes.SetUserActive:
            return {
                ...state,
                users: state.users.map(u => ({
                    ...u,
                    isActive: u.userId === action.payload.userId ? action.payload.isActive : u.isActive
                }))
            }
        case UserActionTypes.AddUserAction:
            return {
                ...state,
                user: action.payload.user
            };
        case UserActionTypes.AddUserSuccess:
            return {
                ...state,
                users: [...state.users, action.payload.newUser]
            };
        case UserActionTypes.EditUserSuccess:
            newUser = state.users;

            const editUser = newUser.find(u => u.userId === action.payload.user.userId);
            if (editUser) {
                editUser.firstName = action.payload.user.firstName;
                editUser.lastName = action.payload.user.lastName;
                editUser.fullName = action.payload.user.fullName;
                editUser.email = action.payload.user.email;
                editUser.phone = action.payload.user.phone;
                // editUser.role = action.payload.user.role;
                // editUser.username = action.payload.user.username;
                // editUser.workPhone = action.payload.user.workPhone;
            }

            return {
                ...state,
                users: [...newUser]
            };
        case UserActionTypes.AssignLocationSuccess:
            return {
                ...state,
                users: state.users.map(u => {
                    if (u.userId !== action.payload.userId) {
                        return u;
                    } else {
                        if (!u.sites) {
                            u.sites = [];
                        }

                        if (action.payload.isAssigned) {
                            u.sites.push(action.payload.site);
                        } else {
                            u.sites = u.sites.filter(s => s.id !== action.payload.siteId);
                        }

                        return u;
                    }
                })
            };
        case UserActionTypes.SetEmailSettingsSuccess:
            return {
                ...state,
                users: state.users.map(u => {
                    if (u.userId !== action.payload.userId) {
                        return u;
                    } else {
                        u.sites.filter(s => s.id === action.payload.siteId).forEach(site => {
                            action.payload.emailSettings.forEach((es: EMAIL_SETTING_TYPE) => {
                                switch (es) {
                                    case EMAIL_SETTING_TYPE.ORDER:
                                        site.permissions.hasOrderEmail = action.payload.isEnabled;
                                        break;
                                    case EMAIL_SETTING_TYPE.SYSTEM:
                                        site.permissions.hasSystemEmail = action.payload.isEnabled;
                                        break;
                                }
                            });
                        });

                        return u;
                    }
                })
            };
        case UserActionTypes.LoadCustomerUsers:
            return {
                ...state,
                loadingUsers: true
            };
        case UserActionTypes.LoadCustomerUsersSuccess:
            return {
                ...state,
                loadingUsers: false,
                customerUsers: action.payload
            };
        case UserActionTypes.DeleteUserSuccess:
            const index = state.users.findIndex(user => user.userId === action.payload);
            state.users.splice(index, 1);

            return {
                ...state,
                users: [...state.users]
            };
        default:
            return state;
    }
}


