import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { noop } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { Login } from 'src/app/shared/actions/auth.actions';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { MarketingService } from 'src/app/core/services/marketing/marketing.service';
import { getPlatformConfiguration } from 'src/app/shared/selectors/branding.selector';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';
import { PlatformRegistrationType } from 'src/app/core/models/branding/platform-registration.enum';
import { Platform } from 'src/app/core/models/branding/platform.model';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

    loginForm: FormGroup;
    loading = false;
    errorMessages: string[] = [];
    loginBannerUrl: string;
    platformConfiguration: Platform;
    branding: BrandingModel;
    registrationType = PlatformRegistrationType;
    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    @ViewChild('iframe', { static: false }) iframe: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private notifier: NotifierService,
        private authenticationService: AuthenticationService,
        private store: Store<AppState>,
        private marketingSvc: MarketingService,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

        this.store.pipe(select(getPlatformConfiguration)).subscribe((configuration: Platform) => {
            if (configuration) {
                this.platformConfiguration = configuration;

                if (configuration.branding) {
                    this.branding = configuration.branding;
                    this.loginBannerUrl = configuration.branding.loginBannerUrl;
                }
            }
        });

        this.activatedRoute.queryParams.subscribe((queryParams) => {
            if(queryParams && queryParams.validationToken) {
                this.checkValidationToken(queryParams.validationToken);
            }
        });
    }

    ngAfterViewInit() {
        if (this.loginBannerUrl && this.branding.loginBannerLink) {
            this.iframe.nativeElement.src = this.loginBannerUrl;
        } else {
            this.marketingSvc.getLoginUrl().subscribe(response => {
                if (response.url && !this.loginBannerUrl) {
                    if (response.url.toUpperCase().match(/\.(JPEG|JPG|PNG)$/) != null) {
                        this.loginBannerUrl = response.url;
                    } else {
                        this.iframe.nativeElement.src = response.url;
                    }
                }
            });
        }
    }

    get form() { return this.loginForm.controls; }

    get logo() { return `${environment.assets.baseUrl}/logo.png`; }

    get createAccountLink() { return `${environment.createAccountLink}`; }

    checkValidationToken(validationToken) {
        this.loading = true;

        this.authenticationService.checkTokenLogin(validationToken)
        .subscribe(loginResponse => {
           if(loginResponse) {
             this.loading = false;
             this.store.dispatch(new Login({ loginResponse }));
             this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.returnUrl || '/order-guide');
           }
         },
         (errorResponse) => {
           if(errorResponse) {
             this.loading =  false;
             this.notifier.notify('error', errorResponse.error.message);
           }
         });
    }

    onSubmit() {
        if (this.loginForm.invalid || this.loading) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.form.username.value, this.form.password.value)
            .pipe(
                tap((loginResponse: any) => {
                    this.loading = false;
                    this.store.dispatch(new Login({ loginResponse }));
                    this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.returnUrl || '/order-guide');
                })
            )
            .subscribe(noop, (response: HttpErrorResponse) => {
                this.loading = false;
                this.notifier.notify('error', response.error.message);
            });
    }

}
