import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAccountType } from 'src/app/core/enums/user-account-type.enum';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { EditUser } from '../../actions/user.actions';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

    formEditU: FormGroup;
    user: any;

    @Output() selected = new EventEmitter();
    @Output() deleteUser = new EventEmitter<number>();

    roles = [
        { id: UserAccountType.ADMIN, name: 'ADMIN' },
        { id: UserAccountType.USER, name: 'USER' },
        { id: UserAccountType.CONTACT, name: 'CONTACT' },
    ];

    constructor(public dialogRef: MatDialogRef<EditUserComponent>,
                private fb: FormBuilder,
                private store: Store<AppState>,
                private dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: { user: any }) {
    }

    ngOnInit() {
        this.formEditU = this.fb.group({
            userId: [],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
            phone: ['', [Validators.pattern(/^[0-9]*$/), Validators.minLength(10), Validators.maxLength(10)]],
            // role: ['', [Validators.required]],
            // username: [''],
            // workPhone: ['', [Validators.pattern(/^[0-9]*$/)]],
        });

        this.user = this.data;

        this.formEditU.patchValue({
            userId: this.user.userId,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.email,
            phone: this.user.phone,
            // role: this.user.userAccountType,
            // username: this.user.username,
            // workPhone: this.user.workPhone,
        });

    }

    get form() { return this.formEditU.controls; }

    onSubmit() {
        if (this.formEditU.invalid) {
            return;
        }

        const user = this.formEditU.value;

        this.store.dispatch(new EditUser(user));
    }

    close(): void {
        this.dialogRef.close();
    }

    delete(): void {
        this.deleteUser.emit(this.user.userId);
        this.close();
    }
}

