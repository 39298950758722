import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScrollService {

    private scrollToTopSubject = new Subject<void>();
    private scrollToTopObservable$ = this.scrollToTopSubject.asObservable();

    constructor() {
    }

    scrollToTop(): void {
        this.scrollToTopSubject.next();
    }

    getScrollToTop(): Observable<void> {
        return this.scrollToTopObservable$;
    }
}
