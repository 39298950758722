import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { SortOptions } from 'src/app/core/enums/sort-options.enum';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';
import { SettingsService } from '../../../core/services/settings/settings.service';
import { SearchOptionType } from '../../../core/enums/search-option-type.enum';
import { LoadVendors } from '../../actions/order-guide.action';

@Component({
    selector: 'app-sort-selection',
    templateUrl: './sort-selection.component.html',
    styleUrls: ['./sort-selection.component.scss']
})
export class SortSelectionComponent implements OnInit {
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    showSortOptions = false;

    options = [
        { id: SortOptions.RELEVANCY, name: 'Relevance', isSelected: false },
        { id: SortOptions.AZ, name: 'A to Z', isSelected: true },
        { id: SortOptions.FAVORITES, name: 'Favorites', isSelected: false },
        { id: SortOptions.RECENT, name: 'Recent', isSelected: false }
    ];

    SortOptions = SortOptions;

    @Output() changed = new EventEmitter<SortOptions>();

    constructor(private store: Store<AppState>,
                private settingsService: SettingsService) {
    }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);

        this.settingsService.getSearchOptions().subscribe(result => {
            if (result.length > 0) {
                result = result.filter(o => o.optionType === SearchOptionType.SORT);

                result.forEach(option => {
                    const sort = this.options.find(o => o.id === option.optionKey);
                    sort.isSelected = option.optionSelected;
                });
            } else {
                this.options.forEach(o => {
                    this.settingsService.saveSearchOptions({
                        optionType: SearchOptionType.SORT,
                        optionKey: o.id,
                        optionSelected: o.isSelected
                    }).subscribe();
                });
            }
        });
    }

    sortOpened(isOpen: boolean) {
        this.showSortOptions = isOpen;
    }

    onClick(option) {
        this.options.forEach(o => {
            o.isSelected = o.id === option.id;
        });

        const requests: Observable<boolean>[] = [];

        this.options.forEach(o => {
            requests.push(this.settingsService.saveSearchOptions({
                optionType: SearchOptionType.SORT,
                optionKey: o.id,
                optionSelected: o.isSelected
            }));
        });

        forkJoin(requests).subscribe(() => {
            this.changed.emit(option.id);
        });
    }

}
