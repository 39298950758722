import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-double-arrow',
  templateUrl: './icon-double-arrow.component.html',
  styleUrls: ['./icon-double-arrow.component.scss']
})
export class IconDoubleArrowComponent {
  @Input() cssClass = 'icon--18';

}
