import { OrderGuideActions, OrderGuideActionTypes, UpdateTagsActionType } from '../actions/order-guide.action';
import { ProductGroupModel } from 'src/app/core/models/product/product-group.model';
import { ProductDetailsModel } from 'src/app/core/models/product/product-details.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { CustomerItemOrderGuide } from 'src/app/core/models/customer-item-order-guide.model';
import { SearchResponse } from 'src/app/core/models/search-response.model';

export interface OrderGuideState {
    orderGuideDetails: ProductDetailsModel;
    loading: boolean;
    categories: any[];
    vendors: any[];
    categoryHistory: any;
    filters: any;
    productDetails: ProductCatalogModel;
    gridViewMode: boolean;
    relatedProducts: any;
    productDetailsPurchaseHistory: any;
    productDetailsPriceTrends: any[];
    cutoffDetails: any[];
    showPricedItemsFilter: boolean;
    productGroup: ProductGroupModel;
    lastProductGroupDeleted: number;
    buyers: Array<any>;
    printLoading: boolean;
    customersList: CustomerItemOrderGuide[],
    productsOrderGuide: SearchResponse<ProductCatalogModel>,
    modifiedPrice: { productId: number, setId: number }
}

export const initialState: OrderGuideState = {
    orderGuideDetails: undefined,
    loading: true,
    categories: [],
    vendors: [],
    categoryHistory: {},
    filters: {},
    productDetails: undefined,
    gridViewMode: true,
    relatedProducts: undefined,
    productDetailsPurchaseHistory: undefined,
    productDetailsPriceTrends: undefined,
    cutoffDetails: [],
    showPricedItemsFilter: true,
    productGroup: new ProductGroupModel(),
    lastProductGroupDeleted: undefined,
    buyers: [],
    printLoading: false,
    customersList: [],
    productsOrderGuide: undefined,
    modifiedPrice: undefined
};

export function orderGuideReducer(
    state = initialState,
    action: OrderGuideActions): OrderGuideState {
    let productToBeUpdatedInOrderGuide = null;
    let newOrderGuideDetails = null;
    switch (action.type) {
        case OrderGuideActionTypes.LoadOrderGuideAction:
            return {
                ...state,
                loading: true
            };
        case OrderGuideActionTypes.OrderGuideLoadedAction:
            return {
                ...state,
                loading: false,
                orderGuideDetails: action.payload.orderGuideDetails,
                filters: action.payload.filters
            };
        case OrderGuideActionTypes.SetProductFavorite:
            newOrderGuideDetails = state.orderGuideDetails;
            if (newOrderGuideDetails) {
                newOrderGuideDetails.items.find(i => i.id === action.payload.productId).isFavorite = action.payload.isFavorite;
            }

            if (state.productDetails && state.productDetails.id === action.payload.productId) {
                state.productDetails.isFavorite = action.payload.isFavorite;
            }

            return {
                ...state,
                orderGuideDetails: newOrderGuideDetails
            };
        case OrderGuideActionTypes.SetProductVendorFavorite:
            const newOrderGuideDetailsForFavorite = state.orderGuideDetails;
            if (newOrderGuideDetailsForFavorite) {
                const product = newOrderGuideDetailsForFavorite.items.find(i => i.id === action.payload.productId);
                if (product) {
                    product.vendors.find(v => v.id === action.payload.vendorId).isFavorite = action.payload.isFavorite;
                }
            }

            let newPdVF = null;
            if (state.productDetails && state.productDetails.id === action.payload.productId) {
                newPdVF = {
                    ...state.productDetails,
                    vendors: state.productDetails.vendors.map(v => ({
                        ...v,
                        isFavorite: v.id === action.payload.vendorId ? action.payload.isFavorite : v.isFavorite
                    }))
                };
            }

            return {
                ...state,
                productDetails: newPdVF !== null ? newPdVF : state.productDetails,
                orderGuideDetails: newOrderGuideDetailsForFavorite
            };
        case OrderGuideActionTypes.ChangeProductName:
            newOrderGuideDetails = state.orderGuideDetails;
            if (!newOrderGuideDetails) {
                return {
                    ...state
                };
            }

            productToBeUpdatedInOrderGuide = newOrderGuideDetails.items.find(i => i.id === action.payload.productId);
            if (productToBeUpdatedInOrderGuide) {
                productToBeUpdatedInOrderGuide.customName = action.payload.newName;
                productToBeUpdatedInOrderGuide.isNameEditing = false;
            }

            let newPd = null;
            if (state.productDetails && state.productDetails.id === action.payload.productId) {
                newPd = {
                    ...state.productDetails,
                    customName: action.payload.newName,
                    isNameEditing: false
                };
            }

            return {
                ...state,
                orderGuideDetails: newOrderGuideDetails,
                productDetails: newPd !== null ? newPd : state.productDetails
            };

        case OrderGuideActionTypes.AddToCartSuccess:
            newOrderGuideDetails = state.orderGuideDetails;
            const { productId } = action.payload;
            if (newOrderGuideDetails) {
                productToBeUpdatedInOrderGuide = newOrderGuideDetails.items.find(i => i.id === productId);
                if (productToBeUpdatedInOrderGuide) {
                    productToBeUpdatedInOrderGuide.vendors = [...productToBeUpdatedInOrderGuide.vendors.map(v => ({
                        ...v,
                        quantity: v.id === action.payload.vendorId ? action.payload.quantity : v.quantity
                    }))];
                }
            }

            let newPdAddToCart = null;
            if (state.productDetails && state.productDetails.id === productId) {
                newPdAddToCart = {
                    ...state.productDetails,
                    vendors: [...state.productDetails.vendors.map(v => ({
                        ...v,
                        quantity: v.id === action.payload.vendorId ? action.payload.quantity : v.quantity
                    }))]
                };
            }

            return {
                ...state,
                productDetails: newPdAddToCart !== null ? newPdAddToCart : state.productDetails,
                orderGuideDetails: newOrderGuideDetails
            };
        case OrderGuideActionTypes.SelectVendor:
            newOrderGuideDetails = state.orderGuideDetails;
            if (!newOrderGuideDetails) {
                return {
                    ...state
                };
            }

            productToBeUpdatedInOrderGuide = newOrderGuideDetails.items.find(i => i.id === action.payload.productId);
            if (productToBeUpdatedInOrderGuide) {
                productToBeUpdatedInOrderGuide.vendors = [...productToBeUpdatedInOrderGuide.vendors.map(v => ({
                    ...v,
                    isSelected: v.id === action.payload.vendorId
                }))];
            }

            return {
                ...state,
                orderGuideDetails: newOrderGuideDetails
            };
        case OrderGuideActionTypes.LoadCategoriesSuccess:
            return {
                ...state,
                categories: action.payload.categories,
                categoryHistory: action.payload.search
            };
        case OrderGuideActionTypes.LoadVendorsSuccess:
            return {
                ...state,
                vendors: action.payload.vendors
            };
        case OrderGuideActionTypes.LoadProductPurchaseHistorySuccess:
            newOrderGuideDetails = state.orderGuideDetails;
            let newProductDetailsPurchaseHistory = state.productDetailsPurchaseHistory;

            if (newOrderGuideDetails) {
                action.payload.purchaseHistory.lastOrdered.forEach(lo => {
                    const productLo = newOrderGuideDetails.items.find(i => i.id === lo.productId);
                    if (productLo) {
                        productLo.lastOrdered = lo;
                    }
                });

                action.payload.purchaseHistory.lastWeek.forEach(lw => {
                    const productLw = newOrderGuideDetails.items.find(i => i.id === lw.productId);
                    if (productLw) {
                        productLw.lastWeek = lw;
                    }
                });
            }

            if (state.productDetails) {
                let wasSet = false;
                action.payload.purchaseHistory.lastWeek.forEach(lw => {
                    if (lw.productId === state.productDetails.id) {
                        newProductDetailsPurchaseHistory = lw;
                        wasSet = true;
                        return;
                    }
                });

                if (!wasSet) {
                    newProductDetailsPurchaseHistory = undefined;
                }
            }

            return {
                ...state,
                orderGuideDetails: newOrderGuideDetails,
                productDetailsPurchaseHistory: newProductDetailsPurchaseHistory
            };
        case OrderGuideActionTypes.AssignTagSuccess:
            newOrderGuideDetails = state.orderGuideDetails;

            if (newOrderGuideDetails) {
                productToBeUpdatedInOrderGuide = newOrderGuideDetails.items.find(i => i.id === action.payload.productId);
                if (productToBeUpdatedInOrderGuide) {
                    productToBeUpdatedInOrderGuide.tags = [...productToBeUpdatedInOrderGuide.tags.map(t => ({
                        ...t,
                        isSelected: t.id === action.payload.tagId ? action.payload.isAssigned : t.isSelected
                    }))];
                }
            }

            let newPdTags = null;
            if (state.productDetails && state.productDetails.id === action.payload.productId) {
                newPdTags = {
                    ...state.productDetails,
                    tags: [...state.productDetails.tags.map(t => ({
                        ...t,
                        isSelected: t.id === action.payload.tagId ? action.payload.isAssigned : t.isSelected
                    }))]
                };
            }

            return {
                ...state,
                orderGuideDetails: newOrderGuideDetails,
                productDetails: newPdTags !== null ? newPdTags : state.productDetails
            };
        case OrderGuideActionTypes.UpdateTags:
            newOrderGuideDetails = state.orderGuideDetails;
            const newProductDetails = state.productDetails;
            switch (action.payload.actionType) {
                case UpdateTagsActionType.Add:
                    productToBeUpdatedInOrderGuide = newOrderGuideDetails.items.find(i => i.id === action.payload.tag.productId);
                    if (productToBeUpdatedInOrderGuide) {
                        productToBeUpdatedInOrderGuide.tags = [...productToBeUpdatedInOrderGuide.tags, action.payload.tag];
                    }

                    if (newProductDetails && newProductDetails.id === action.payload.tag.productId) {
                        newProductDetails.tags = [...newProductDetails.tags, action.payload.tag];
                    }

                    // add tag to all the products
                    newOrderGuideDetails.items.filter(i => i.id !== action.payload.tag.productId)
                        .forEach(i => {
                            i.tags = [...i.tags, { ...action.payload.tag, isSelected: false }];
                        });
                    break;
                case UpdateTagsActionType.Delete:
                    newOrderGuideDetails.items.forEach(i => {
                        i.tags = i.tags.filter(t => t.id !== action.payload.tag.id);
                    });
                    break;

                case UpdateTagsActionType.Update: {
                    newOrderGuideDetails.items.forEach(item => {
                        const index = item.tags.findIndex(tag => tag.id === action.payload.tag.id);
                        item.tags[index].name = action.payload.tag.name;
                    });
                    break;
                }
            }
            return {
                ...state,
                orderGuideDetails: newOrderGuideDetails,
                productDetails: newProductDetails
            };
        case OrderGuideActionTypes.ProductLoadedAction:
            return {
                ...state,
                productDetails: action.payload.productDetails
            };
        case OrderGuideActionTypes.SelectProductImage:
            if (state.productDetails && state.productDetails.id !== action.payload.productId) {
                return {
                    ...state
                };
            }

            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    imageUrl: action.payload.imageUrl
                }
            };
        case OrderGuideActionTypes.ToggleGridViewMode:
            return {
                ...state,
                gridViewMode: action.payload.showGridViewMode
            };
        case OrderGuideActionTypes.SetVendorFavorite:
            newOrderGuideDetails = state.orderGuideDetails;

            if (newOrderGuideDetails) {
                newOrderGuideDetails.items.forEach(i => {
                    i.vendors.forEach(v => {
                        if (v.id === action.payload.vendorId) {
                            v.isFavoriteForSearch = action.payload.isFavorite;
                        }
                    });
                });
            }

            return {
                ...state,
                orderGuideDetails: newOrderGuideDetails,
                vendors: state.vendors.map(v => ({
                    ...v,
                    isFavorite: v.customerSiteID === action.payload.vendorId ? action.payload.isFavorite : v.isFavorite
                }))
            };
        case OrderGuideActionTypes.RelatedProductsLoadedAction:
            return {
                ...state,
                relatedProducts: action.payload.relatedProducts
            };
        case OrderGuideActionTypes.PriceTrendLoadedAction:
            return {
                ...state,
                productDetailsPriceTrends: action.payload.priceTrends
            };
        case OrderGuideActionTypes.VendorCutOffsLoaded:
            return {
                ...state,
                cutoffDetails: action.payload.cutoffDetails
            };
        case OrderGuideActionTypes.UpdateOrderGuideLayout:
            return {
                ...state,
                gridViewMode: action.payload.gridViewMode,
                showPricedItemsFilter: action.payload.showPricedItemsFilter
            };
        case OrderGuideActionTypes.SaveProductGroup:
            return {
                ...state,
                loading: true
            };
        case OrderGuideActionTypes.UpdateProductGroup:
            state.productGroup = action.payload;
            return {
                ...state,
                productGroup: {
                    ...state.productGroup
                }
            };
        case OrderGuideActionTypes.LoadProductGroupSuccess:
            action.payload.selectedProducts.forEach(selectedProduct => {
                const orderGuideIndex = state.orderGuideDetails.items.findIndex(orderGuide => orderGuide.id === selectedProduct.productId);
                if (orderGuideIndex !== -1) {
                    state.orderGuideDetails.items[orderGuideIndex].isSelected = true;
                }
            });

            return {
                ...state,
                productGroup: action.payload
            };
        case OrderGuideActionTypes.SaveProductGroupSuccess:
            return {
                ...state,
                loading: false,
                productGroup: action.payload
            };
        case OrderGuideActionTypes.SaveProductGroupFailed:
            return {
                ...state,
                loading: false,
                productGroup: action.payload
            };
        case OrderGuideActionTypes.LastProductGroupDeleted:
            return {
                ...state,
                lastProductGroupDeleted: action.payload
            };
        case OrderGuideActionTypes.UpdateProductsList:
            return {
                ...state,
                orderGuideDetails: {
                    ...state.orderGuideDetails,
                    items: [...state.orderGuideDetails.items, action.payload]
                }
            };
        case OrderGuideActionTypes.DeleteProductGroup:
            return {
                ...state,
                loading: true
            };
        case OrderGuideActionTypes.LoadBuyersSuccess:
            return {
                ...state,
                buyers: action.payload
            };
        case OrderGuideActionTypes.PrintOrderGuideLoading:
            return {
                ...state,
                printLoading: action.payload.loading
            };
        case OrderGuideActionTypes.ClearOrderGuide:
            return {
                ...state,
                loading: true,
                orderGuideDetails: undefined
            };
        case OrderGuideActionTypes.SaveCustomerOrderGuideSuccess:
        case OrderGuideActionTypes.LoadCustomerListSuccess:
            return {
                ...state,
                customersList: action.payload
            }
        case OrderGuideActionTypes.SaveProductSpecialPrice:
        case OrderGuideActionTypes.LoadProductsOrderGuide:
            return {
                ...state,
                loading: true
            }
        case OrderGuideActionTypes.LoadProductsOrderGuideSuccess:
            return {
                ...state,
                loading: false,
                productsOrderGuide: action.payload
            }
        case OrderGuideActionTypes.SaveProductSpecialPriceSuccess:
            return {
                ...state,
                loading: false,
                modifiedPrice: action.payload
            }
        default:
            return state;
    }
}
